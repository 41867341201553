exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-components-about-us-js": () => import("./../../../src/pages/about-us/components/about-us.js" /* webpackChunkName: "component---src-pages-about-us-components-about-us-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-contact-us-components-contact-us-js": () => import("./../../../src/pages/contact-us/components/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-components-contact-us-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-home-components-about-js": () => import("./../../../src/pages/home/components/about.js" /* webpackChunkName: "component---src-pages-home-components-about-js" */),
  "component---src-pages-home-components-landing-js": () => import("./../../../src/pages/home/components/landing.js" /* webpackChunkName: "component---src-pages-home-components-landing-js" */),
  "component---src-pages-home-components-particles-js": () => import("./../../../src/pages/home/components/particles.js" /* webpackChunkName: "component---src-pages-home-components-particles-js" */),
  "component---src-pages-home-components-plans-js": () => import("./../../../src/pages/home/components/plans.js" /* webpackChunkName: "component---src-pages-home-components-plans-js" */),
  "component---src-pages-home-components-quote-js": () => import("./../../../src/pages/home/components/quote.js" /* webpackChunkName: "component---src-pages-home-components-quote-js" */),
  "component---src-pages-home-components-services-js": () => import("./../../../src/pages/home/components/services.js" /* webpackChunkName: "component---src-pages-home-components-services-js" */),
  "component---src-pages-home-components-why-js": () => import("./../../../src/pages/home/components/why.js" /* webpackChunkName: "component---src-pages-home-components-why-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-packages-components-packages-js": () => import("./../../../src/pages/our-packages/components/packages.js" /* webpackChunkName: "component---src-pages-our-packages-components-packages-js" */),
  "component---src-pages-our-packages-index-js": () => import("./../../../src/pages/our-packages/index.js" /* webpackChunkName: "component---src-pages-our-packages-index-js" */),
  "component---src-pages-our-services-components-our-services-js": () => import("./../../../src/pages/our-services/components/our-services.js" /* webpackChunkName: "component---src-pages-our-services-components-our-services-js" */),
  "component---src-pages-our-services-index-js": () => import("./../../../src/pages/our-services/index.js" /* webpackChunkName: "component---src-pages-our-services-index-js" */),
  "component---src-pages-outsourcing-components-outsourcing-js": () => import("./../../../src/pages/outsourcing/components/outsourcing.js" /* webpackChunkName: "component---src-pages-outsourcing-components-outsourcing-js" */),
  "component---src-pages-outsourcing-index-js": () => import("./../../../src/pages/outsourcing/index.js" /* webpackChunkName: "component---src-pages-outsourcing-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

